<template>
  <div>
    <van-nav-bar
      title="历史详情"
      left-text="返回"
      left-arrow
      @click-left="onClickBank"
    />
    <div class="history_detail">
      <div class="history_detail_box">
        <div class="history_detail_left">
          <img :src="image" alt="" class="history_detail_left_img" />
          <div class="history_detail_left_box">
            <div class="history_detail_plant_name">
              {{ crop_type_name }}
            </div>
            <div class="history_detail_cycle">
              {{
                status === 0
                  ? currentCropLife
                  : status === 1
                  ? "收获"
                  : status === 2
                  ? "改种"
                  : ""
              }}
            </div>
          </div>
        </div>
        <div class="history_detail_right">
          <div class="history_detail_right_create_data">{{ plant_time }}</div>
          <div class="carbon" v-show="carbon_num !== 0">
            {{ "碳 " + carbon_num }}
          </div>
          <div class="history_detail_right_up_data">
            {{
              status === 0
                ? ""
                : status === 1
                ? status_time.slice(0, 10)
                : status === 2
                ? status_time.slice(0, 10)
                : ""
            }}
          </div>
        </div>
      </div>
    </div>
    <van-collapse v-model="activeNames" accordion @change="activeName($event)">
      <van-collapse-item v-for="(item, key) in list" :name="key" :key="key">
        <template #title>
          <div class="history_deltail_collapse">
            <div class="history_deltail_time">{{ key }}</div>
            <div
              class="history_deitail_tag"
              v-for="(tItem, index) in item"
              :key="index"
            >
              <van-tag type="primary">{{ tItem.phenology_oper_name }}</van-tag>
            </div>
          </div>
        </template>
        <div class="collapse-item-content">
          <div class="handle-item" v-for="(tItem, index) in item" :key="index">
            <div class="text-area">
              <span class="item-title"> {{ tItem.phenology_oper_name }}: </span>
              <span class="item-content">{{ tItem.content }}</span>
            </div>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { NavBar, Collapse, CollapseItem, Tag } from "vant";
import { getHistoryList, getHistoryTop } from "@/service/Addfarmland.js";
export default {
  name: "HistoryDetail",
  data() {
    return {
      historyTop: [],
      currentCropLife: "",
      crop_type_name: "",
      status: "",
      plant_time: "",
      status_time: "",
      image: "",
      activeNames: [],
      list: {},
      isactive: 0,
      act: false
    };
  },
  components: {
    VanNavBar: NavBar,
    VanCollapse: Collapse,
    VanCollapseItem: CollapseItem,
    VanTag: Tag
  },
  mounted() {
    this.getList();
    this.getHistoryToplist();
  },
  methods: {
    onClickBank() {
      this.$router.go(-1);
    },
    getList() {
      const id = this.$route.params && this.$route.params.id;
      getHistoryList(id).then((res) => {
        this.list = res.data;
      });
    },
    getHistoryToplist() {
      const id = this.$route.params && this.$route.params.id;
      getHistoryTop(id).then((res) => {
        this.crop_type_name = res.data.crop_type_name;
        this.status = res.data.status;
        this.plant_time = res.data.plant_time;
        this.status_time = res.data.status_time;
        this.image = res.data.cropImage;
        this.carbon_num = res.data.carbon_num;
      });
    },
    handelTag(id, e) {
      this.isactive = id;
      if (this.isactive === id) {
        this.act = true;
      }
    },
    activeName(e) {
    }
  }
};
</script>

<style lang="scss" scoped>
.history_detail {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  .history_detail_box {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 25px;
    margin-bottom: 10px;
    justify-content: space-between;
    .history_detail_left {
      margin-left: 10px;
      display: flex;
      .history_detail_left_img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 90px;
        height: 90px;
      }
      .history_detail_left_box {
        padding-top: 25px;
        padding-left: 10px;
        .history_detail_plant_name {
          padding-bottom: 10px;
        }
        .history_detail_cycle {
          padding-top: 10px;
        }
      }
    }
    .history_detail_right {
      padding-top: 25px;
      padding-right: 10px;
      .history_detail_right_create_data {
        padding-bottom: 10px;
      }
      .history_detail_right_up_data {
        padding-top: 10px;
      }
      .carbon {
        background: #d0e17d;
        padding: 0 8px;
        display: inline-block;
      }
    }
  }
}
.history_deltail_collapse {
  display: flex;
  .history_deltail_time {
    padding-left: 5px;
    padding-right: 5px;
  }
  .history_deitail_tag {
    padding-right: 5px;
  }
}
.active {
  background: red;
}
.collapse-item-content {
  .handle-item {
    margin: 6px 0;
    border: 1px solid #ddd;
    padding: 4px 2px;
    color: #333;
    .item-title {
      font-weight: 600;
    }
  }
}
</style>
